import * as Sentry from "@sentry/react";
import { useUserContext } from "@overrides_venia/peregrine/context/user";
import { useCheckoutContext } from "@magento/peregrine/lib/context/checkout";
import { useEffect, useState } from "react";
export var useSentry = () => {
  var [{
    currentUser,
    isApp
  }] = useUserContext();
  var [hasReplayBeenStarted, setHasReplayBeenStarted] = useState(false);
  var [{
    tableData
  }] = useCheckoutContext();
  var sentryReplay = Sentry.getReplay();
  var replayId = sentryReplay.getReplayId();
  useEffect(() => {
    if (replayId && hasReplayBeenStarted) {
      console.log("Starting Sentry replay ", replayId);
      sentryReplay.flush({
        continueRecording: true
      });
      exponea.track("sentry_replay_id", {
        sentry_replay_id: replayId
      });
    }
  }, [replayId, hasReplayBeenStarted]);
  useEffect(() => {
    var logThreshold = parseFloat(process.env.SENTRY_REPLAY_RATE);
    var logValue = Math.random();
    var isLogActive = logValue < logThreshold;
    if (isLogActive) {
      console.log("sampling OK Sentry replay ", logValue, logThreshold);
      setHasReplayBeenStarted(true);
    } else {
      console.log("sampling KO Sentry replay ", logValue, logThreshold);
    }
    return () => {
      if (hasReplayBeenStarted) {
        console.log("Sentry replay cleanup");
        sentryReplay.flush();
        Sentry.flush();
      }
    };
  }, []);
  useEffect(() => {
    if (currentUser.email) {
      Sentry.setUser({
        email: currentUser.email
      });
    }
  }, [currentUser.email]);
  useEffect(() => {
    if (isApp) {
      Sentry.setTag("platform", "app");
      setHasReplayBeenStarted(true);
    }
  }, [isApp]);
  useEffect(() => {
    var _tableData$store;
    var isCheckoutActive = tableData.sales_type ? tableData.sales_type !== "CASUALDINING" : false;
    Sentry.setTag("store", (_tableData$store = tableData.store) === null || _tableData$store === void 0 ? void 0 : _tableData$store.ls_store_id);
    Sentry.setTag("salesType", tableData.sales_type);
    Sentry.setTag("table", tableData.table);
    if (isCheckoutActive) {
      setHasReplayBeenStarted(true);
    }
  }, [tableData]);
  return null;
};