import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useAppContext } from '@overrides_venia/peregrine/context/app';
import { GET_STORES } from "@Src_main/pages/Stores/stores.gql";
import { GET_TABLE_MAP } from '@Components/ModalPickupPoint/tableMap.gql';
import { useCheckoutContext } from '@magento/peregrine/lib/context/checkout';
import { castToCorrectType } from './generalUtils';
export function useStores() {
  var [{
    stores
  }, {
    actions: {
      setStores
    }
  }] = useAppContext();
  var [{
    tableData
  }] = useCheckoutContext();
  var [tableMap, setTableMap] = useState([]);
  var [tableNumber, setTableNumber] = useState(0);
  var [getStores, {
    data,
    loading
  }] = useLazyQuery(GET_STORES, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      var storesData = data.stores.map(x => {
        var configsObject = x.config.reduce((obj, item) => {
          obj[item.key] = castToCorrectType(item.value, item.key);
          return obj;
        }, {});
        var storeHours = x.store_hours.sort((a, b) => {
          if (a.day_of_week === b.day_of_week) {
            return a.open_time.localeCompare(b.open_time);
          }
          return a.day_of_week - b.day_of_week;
        });
        var groupedHours = storeHours.reduce((acc, _ref) => {
          var {
            day_of_week,
            open_time,
            close_time
          } = _ref;
          // Initialize an array for each day if it doesn't already exist
          if (!acc[day_of_week]) {
            acc[day_of_week] = [];
          }

          // Add the open and close times as an interval to the day's array
          acc[day_of_week].push({
            open: open_time,
            close: close_time
          });
          return acc;
        }, {});
        delete x.config;
        var storeEnriched = _objectSpread(_objectSpread(_objectSpread({}, x), {}, {
          id: x.ls_store_id
        }, configsObject), {}, {
          reducedBookingDays: [1, 2, 3, 4, 5],
          store_hours: groupedHours,
          sales_type: recursiveToCamel(x.sales_type)
        });
        return storeEnriched;
      });
      setStores(storesData);
    }
  });
  var [getTableMap] = useLazyQuery(GET_TABLE_MAP, {
    fetchPolicy: "no-cache",
    variables: {
      restaurant_id: tableData.cps
    },
    onCompleted: data => {
      setTableMap(data.restaurantTable);
    }
  });
  useEffect(() => {
    if (tableMap && tableData.cpt) {
      var _tableMap$find;
      var tableNum = (_tableMap$find = tableMap.find(x => x.table_id === tableData.cpt)) === null || _tableMap$find === void 0 ? void 0 : _tableMap$find.table_number;
      if (tableNum) {
        setTableNumber(tableNum);
      }
    }
  }, [tableData.cpt, tableMap]);
  useEffect(() => {
    if (stores.length === 0) {
      getStores();
    }
  }, []);
  useEffect(() => {
    if (tableData.cps) {
      getTableMap();
    }
  }, [tableData.cps]);
  return {
    stores,
    tableMap,
    tableNumber
  };
}
;
var recursiveToCamel = item => {
  if (Array.isArray(item)) {
    return item.map(el => recursiveToCamel(el));
  } else if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }
  return Object.fromEntries(Object.entries(item).map(_ref2 => {
    var [key, value] = _ref2;
    return [key.replace(/([-_][a-z])/gi, c => c.toUpperCase().replace(/[-_]/g, '')), recursiveToCamel(value)];
  }));
};
export default useStores;