import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import { gql } from "@apollo/client";
import { CheckoutPageFragment } from "@overrides_venia/venia-ui/components/CheckoutPage/checkoutPageFragments.gql";
export var GET_CUSTOMER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    query GetCustomerAfterSignIn {\n        customer {\n            email\n            firstname\n            lastname\n            date_of_birth\n            is_subscribed\n            default_shipping\n            gender\n            addresses {\n                default_billing\n                default_shipping\n                telephone\n                city\n                street\n                region {\n                    region_code\n                    region\n                }\n                postcode\n                country_code\n                id\n            }\n            lsAccount {\n                card_id\n                contact_id\n                scheme {\n                    point_balance\n                }\n                coupons {\n                    coupon_description\n                    coupon_details\n                    coupon_expire_date\n                    info\n                    offer_id\n                    barcode\n                    items\n                    additional_data {\n                        key\n                        value\n                    }\n                }\n                customer_attributes {\n                    id\n                    value\n                }\n            }\n            avatar {\n                avatar\n            }\n        }\n    }\n"])));
export var SIGN_IN = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    mutation SignIn($email: String!, $password: String!) {\n        generateCustomerToken(email: $email, password: $password) {\n            token\n        }\n    }\n"])));
export var CREATE_CART = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    mutation CreateCartAfterSignIn {\n        cartId: createEmptyCart\n    }\n"])));
export var MERGE_CARTS = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    mutation MergeCartsAfterSignIn($sourceCartId: String!, $destinationCartId: String!) {\n        mergeCarts(source_cart_id: $sourceCartId, destination_cart_id: $destinationCartId) {\n            ...CheckoutPageFragment\n        }\n    }\n    ", "\n"])), CheckoutPageFragment);
export default {
  createCartMutation: CREATE_CART,
  getCustomerQuery: GET_CUSTOMER,
  mergeCartsMutation: MERGE_CARTS,
  signInMutation: SIGN_IN
};